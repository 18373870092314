<script>
import { Input, Button } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'
export default {
  props: {
    setValue: {
      type: Function,
      default: null,
    },
    getValue: {
      type: Function,
    },
  },
  methods: {
    getValueText() {
      return (this.getValue({ key: 'lng' }) || '') + ',' + (this.getValue({ key: 'lat' }) || '')
    },
    getValueObj() {
      return {
        lng: this.getValue({ key: 'lng' }),
        lat: this.getValue({ key: 'lat' }),
      }
    },
    getSearch() {
      getAction(`/api/gxlkoperation/common/getLocation?location=${this.getValueText()}`).then((e) => {
        this.setValue('address', e.address)
        this.setValue('areaCode', e.areaCode)
        this.setValue('areaName', e.areaName)
        this.setValue('provinceName', e.provinceName)
        this.setValue('provinceCode', e.provinceCode)
        this.setValue('cityName', e.cityName)
        this.setValue('cityCode', e.cityCode)
        this.setValue('streetCode', e.streetCode || '')
        this.setValue('streetName', e.streetName || '')
      })
    },
    onSearch() {
      apiTool.openMap({
        title: '打开地图',
        value: [this.getValueObj()],
        onSuccess: (data) => {
          const { lat, lng } = data
          this.setValue('lat', String(lat))
          this.setValue('lng', String(lng))
          this.getSearch(lat, lng)
        },
      })
    },
  },
  render() {
    return (
      <div class="amap-location">
        <Input placeholder="请选择定位" value={this.getValueText()} />
        <Button type="primary" onClick={this.onSearch} style={{ width: '80px' }}>
          定位
        </Button>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.amap-location {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
</style>
