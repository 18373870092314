import { Modal, Drawer, message } from 'ant-design-vue'
import Vue from 'vue'
import FormView from '../components/FormView'
import MapView from '../components/MapView'
import MapTheView from '../components/MapTheView'
import MapMarkersView from '../components/MapMarkersView'
import PopButton from '../components/PopButton'
/**
 * 显示弹窗
 */
export const showModal = function showModal({ title, form, typeData, mode, success, formData, view, viewProps = {} }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.$refs._form.sumbit()
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderView() {
        const View = view
        return <View props={viewProps} />
      },
    },
    render() {
      return (
        <Modal title={title} visible={this.visible} onCancel={this.onCancel} onOk={this.onOk}>
          {view ? (
            this.renderView()
          ) : (
            <FormView
              ref={'_form'}
              data={form}
              layout={'horizontal'}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              typeData={typeData}
              form={formData}
              mode={mode}
              onSuccess={(data) => success({ data, setHidden: this.onCancel })}
            />
          )}
        </Modal>
      )
    },
  })
}

/**
 * 显示抽屉
 */
export const showDrawer = function showDrawer({
  title,
  form,
  typeData,
  mode,
  success,
  formData,
  width,
  view,
  viewProps = {},
  formProps = {},
  foot = [],
}) {
  const body = document.body
  const div = document.createElement('div')
  div.style.zIndex = 9999
  body.appendChild(div)
  const vm = new Vue({
    el: div,
    data() {
      return {
        visible: true,
        update: false,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.$refs._form.sumbit()
      },
      onSuccess(data) {
        console.log('表单成功', data)
      },
      renderFoot() {
        const footData = typeof foot == 'function' ? foot({ close: this.onCancel, submit: this.onOk }) : foot
        return (
          <div class="drawer-modal-foot">
            {footData.map((e) => {
              return <PopButton item={e} onClick={() => e.onClick({ close: this.onCancel, submit: this.onOk })} />
            })}
          </div>
        )
      },
      renderView() {
        const View = view
        return (
          <View
            ref={'_form'}
            typeData={this.getTypeData()}
            onSuccess={(data) => success({ data, setHidden: this.onCancel })}
            props={viewProps}
            formData={formData}
          />
        )
      },
      getTypeData() {
        return typeof typeData == 'function' ? typeData() : typeData
      },
      renderBody() {
        return (
          <div class="drawer-modal-body">
            {view ? (
              this.renderView()
            ) : (
              <FormView
                ref={'_form'}
                data={form}
                cols={24}
                typeData={this.getTypeData()}
                form={formData}
                mode={mode}
                props={formProps}
                onSuccess={(data) => success({ data, setHidden: this.onCancel })}
              />
            )}
          </div>
        )
      },
    },
    render() {
      return (
        <Drawer
          title={title}
          visible={this.visible}
          onClose={this.onCancel}
          onOk={this.onOk}
          placement={'right'}
          width={width}
        >
          <div class="drawer-modal-main">
            {this.visible && this.renderBody()}
            {foot && this.renderFoot()}
          </div>
        </Drawer>
      )
    },
  })
  return vm
}

// 打开地图
export const openMap = function openMap({ title, value, onSuccess }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        const data = this.$refs.map.getData()
        if (data) {
          onSuccess && onSuccess(data)
          this.onCancel()
        } else {
          message.error('请在地图上选择地址')
        }
      },
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapView ref="map" value={value} />
        </Modal>
      )
    },
  })
}

// 打开地图
export const openTheMap = function openTheMap({ title, value, onSuccess }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        const data = this.$refs.map1.getData()
        if (data) {
          onSuccess && onSuccess(data)
          this.onCancel()
        } else {
          message.error('请在地图上选择地址')
        }
      },
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapTheView ref="map1" value={value} />
        </Modal>
      )
    },
  })
}

// 打开地图标记
export const openMapMarkers = function openMapMarkers({ title, value }) {
  const body = document.body
  const div = document.createElement('div')
  body.appendChild(div)
  new Vue({
    el: div,
    data() {
      return {
        visible: true,
      }
    },
    methods: {
      onCancel() {
        this.visible = false
        this.$nextTick(() => {
          const dom = document.getElementsByClassName('ant-modal-root')
          Array.from(dom).forEach((e) => {
            e.parentElement.remove()
          })
        })
      },
      onOk() {
        this.onCancel()
      },
    },
    render() {
      return (
        <Modal
          title={title}
          okText="确定"
          cancelText="取消"
          visible={this.visible}
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <MapMarkersView ref="map" value={value} />
        </Modal>
      )
    },
  })
}

export const validateNumber = (rule, value, callback) => {
  if (value && !/^[0-9]*$/.test(Number(value))) {
    callback('请输入数字')
  } else {
    callback()
  }
}

export const getDevState = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'dev'
  } else if (location.href.indexOf('interface.palm.ztesa.work') > -1) {
    return 'nongqibang'
  } else {
    return 'ztesa'
  }
}
export const jumpLogin = () => {
  if (getDevState() === 'dev') {
    localStorage.setItem('historyUrl', location.href)
    window.location.href = '/user/login'
  } else if (getDevState() === 'nongqibang') {
    location.href = 'http://login.nongqibang.com:8200/login'
  } else {
    location.href = 'https://login.ztesa.com.cn/login'
  }
}

export const jumpRegister = () => {
  if (getDevState() === 'dev') {
    message.info('请在生产环境下使用控制台!')
    return false
  } else if (getDevState() === 'nongqibang') {
    location.href = 'http://login.nongqibang.com:8200/index?redirect=' + encodeURIComponent(location.href)
  } else {
    location.href = 'https://login.ztesa.com.cn/index?redirect=' + encodeURIComponent(location.href)
  }
}

/**
 * 获取vip金额
 */
export const getVipPrice = (result) => {
  const getVipPic = function (pic1, pic2, index) {
    let price = Math.round(Number(pic1 - (index * (pic1 - pic2)) / 6) * 100) / 100
    return price
  }
  const fill = Array(6).fill('')
  fill.forEach((e, index) => {
    // eslint-disable-next-line prettier/prettier
    const data = result.vipPrice && result.salePrice ? getVipPic(result.salePrice, result.vipPrice, index + 1) : '-'
    result[`v${index + 1}`] = data
  })
  return result
}

// 获取筛选按钮
export const getButtonName = () => {
  const lButton = JSON.parse(localStorage.getItem('button')) || {}
  const url = location.pathname ? location.pathname.split('/') : []
  const url2 = url[url.length - 1]
  const currentPath = lButton['/' + url2]
  if (currentPath) {
    return currentPath.map((e) => e.name)
  } else {
    return []
  }
}

export const uploadFile = ({ success } = {}) => {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = '.png,.jpeg,.jpg'
  input.onchange = function (e) {
    const { token, url } = JSON.parse(localStorage.getItem('qiniu')) || {}
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('token', token)
    fetch('//upload.qiniup.com/', {
      method: 'POST',
      body: formData,
      headers: {
        token,
      },
    })
      .then((e) => e.json())
      .then((e) => success(url + e.hash))
  }
  input.click()
}

export const getUUID = function () {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-'

  var uuid = s.join('')
  return uuid
}

export default {
  showModal,
  showDrawer,
  openMap,
  openTheMap,
  openMapMarkers,
  validateNumber,
  jumpLogin,
  getVipPrice,
  getButtonName,
  uploadFile,
  getUUID,
}
