<script>
import { Select } from 'ant-design-vue'
export default {
  props: ['item', 'typeData', 'value', 'mode'],
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.value || this.item.defaultValue)
      this.$emit('changeSelect', this.value || this.item.defaultValue)
    }
  },
  methods: {
    getValue() {
      if (this.item.sort) {
        return [].concat(this.value).sort((a, b) => Number(a) - Number(b))
      } else {
        return this.value
      }
    },
    onChange(e) {
      if (this.item.onSelectChange) {
        this.item.onSelectChange(e)
      }
      this.$emit('changeSelect', e)
    },
  },
  render() {
    return (
      <Select
        props={this.item.props}
        disabled={this.mode == 'detail'}
        value={this.getValue()}
        style={{ width: '100%' }}
        onChange={(e) => this.onChange(e)}
        placeholder={this.item.placeholder ? this.item.placeholder : '请选择' + (this.item.name || this.item.label)}
        {...this.item.props}
      >
        {(this.typeData || []).map((e) => {
          return <Select.Option value={e.value}>{e.name}</Select.Option>
        })}
      </Select>
    )
  },
}
</script>
